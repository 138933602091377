<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Pods</h1>
        </v-col>
        <v-col class="pl-12"></v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  v-on="on"
                  class="mr-2"
                  @click="openPodDialog()"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add New Pod</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" v-for="pod in pods" :key="`${pod.id}-pod-col`">
          <v-card>
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title class="text-h5" v-text="pod.name" />
                <v-card-text>
                  <div class="mb-3">
                    <div class="subtitle-1">Description</div>
                    <div v-text="pod.description" />
                  </div>
                  <div>
                    <div class="subtitle-1">Standard Rate</div>
                    <div>£{{ pod.standard_room_rate }}</div>
                  </div>
                </v-card-text>
              </div>
              <v-avatar class="ma-3" color="grey" size="125" tile>
                <v-img :src="pod.thumbnail_image"></v-img>
              </v-avatar>
            </div>
            <v-card-text>
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header
                    >Seasonal Rates</v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    <div class="mb-3">
                      <div v-if="pod.rates.length > 0">
                        <v-simple-table>
                          <thead>
                            <tr>
                              <th>From</th>
                              <th>To</th>
                              <th>Rate</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="rate in pod.rates"
                              :key="`${rate.id}-rate-row`"
                            >
                              <td v-text="rate.formatted_dates.from" />
                              <td v-text="rate.formatted_dates.to" />
                              <td>£{{ rate.room_rate }}</td>
                              <td>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      x-small
                                      depressed
                                      color="red lighten-4 red--text"
                                      v-on="on"
                                      @click="openDelete(rate)"
                                    >
                                      <v-icon x-small>mdi-archive</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Archive</span>
                                </v-tooltip>
                              </td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                      </div>
                      <div v-else>
                        There are no Seasonal Rates for this Pod.
                      </div>
                    </div>
                    <v-btn
                      depressed
                      small
                      color="green lighten-4 green--text"
                      @click="openRateDialog(pod.id)"
                    >
                      <v-icon small left>mdi-plus</v-icon> Add Rate
                    </v-btn>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card-text>
            <v-card-actions class="pa-4">
              <v-btn
                depressed
                small
                color="blue lighten-4 blue--text"
                @click="openPodDialog(pod)"
              >
                <v-icon small left>mdi-pencil</v-icon> Edit
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="podDialog.open"
      max-width="500"
      @click:outside="resetPodDialog"
    >
      <v-card>
        <v-card-title class="headline"
          >{{ podDialog.isEditing ? "Edit" : "Add a New" }} Pod</v-card-title
        >
        <v-divider></v-divider>
        <v-card-text class="pt-5">
          <v-text-field
            label="Name *"
            outlined
            v-model="podDialog.fields.name"
            background-color="white"
            :error="podDialog.errors.hasOwnProperty('name')"
            :error-messages="podDialog.errors['name']"
          ></v-text-field>
          <v-textarea
            label="Description *"
            v-model="podDialog.fields.description"
            outlined
            background-color="white"
            :error="podDialog.errors.hasOwnProperty('description')"
            :error-messages="podDialog.errors['description']"
          ></v-textarea>
          <v-text-field
            label="Standard Room Rate *"
            type="number"
            min="0.00"
            step="1.00"
            prefix="£"
            outlined
            v-model="podDialog.fields.standard_room_rate"
            background-color="white"
            :error="podDialog.errors.hasOwnProperty('standard_room_rate')"
            :error-messages="podDialog.errors['standard_room_rate']"
          ></v-text-field>
          <v-file-input
            label="Thumbnail Image"
            v-model="podDialog.fields.thumbnail_image"
            accept="image/png, image/jpeg"
            placeholder="Choose Image"
            outlined
            :error="podDialog.errors.hasOwnProperty('thumbnail_image')"
            :error-messages="podDialog.errors['thumbnail_image']"
          ></v-file-input>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="resetPodDialog()">Close</v-btn>
          <v-btn
            color="accent"
            text
            type="submit"
            :loading="podDialog.loading"
            @click="savePod"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="rateDialog.open"
      max-width="400"
      @click:outside="resetRateDialog"
    >
      <v-card>
        <v-card-title class="headline"
          >{{ rateDialog.isEditing ? "Edit" : "Add a New" }} Seasonal
          Rate</v-card-title
        >
        <v-divider></v-divider>
        <v-card-text class="pt-5">
          <v-text-field
            label="Rate *"
            type="number"
            min="0.00"
            step="1.00"
            prefix="£"
            outlined
            v-model="rateDialog.fields.room_rate"
            background-color="white"
          ></v-text-field>
          <v-date-picker
            v-model="rateDialog.dates"
            :min="rateDialog.minDate"
            range
            full-width
            first-day-of-week="1"
          ></v-date-picker>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="resetRateDialog()">Close</v-btn>
          <v-btn
            color="accent"
            text
            type="submit"
            :loading="rateDialog.loading"
            @click="saveRate"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteRate.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Archive Rate</v-card-title>
        <v-card-text>Are you sure you wish to archive this rate?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteRate.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: "Glamping",
          disabled: true,
        },
        {
          text: "Pods",
          disabled: true,
        },
      ],
      podDialog: {
        open: false,
        loading: false,
        errors: {},
        isEditing: false,
        pod: {},
        fields: {
          name: null,
          description: null,
          thumbnail_image: null,
          standard_room_rate: null,
        },
      },
      deleteRate: {
        dialog: false,
        rate: {},
        loading: false,
      },
      rateDialog: {
        open: false,
        loading: false,
        errors: {},
        isEditing: false,
        minDate: new Date().toISOString().slice(0, 10),
        rate: {},
        dates: [],
        fields: {
          pod_id: null,
          room_rate: null,
          from: null,
          to: null,
        },
      },
    };
  },

  computed: {
    pods() {
      return this.$store.getters["arnprior/podsStore/all"];
    },
  },

  methods: {
    openDelete(rate) {
      this.deleteRate.rate = rate;
      this.deleteRate.dialog = true;
    },

    resetDelete() {
      this.deleteRate.dialog = false;
      this.deleteRate.rate = {};
      this.deleteRate.loading = false;
    },

    saveDelete() {
      this.deleteRate.loading = true;

      this.$store
        .dispatch("arnprior/podsStore/deleteRate", {
          appId: this.$route.params.id,
          rateId: this.deleteRate.rate.id,
          podId: this.deleteRate.rate.glamping_pod_id,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteRate.loading = false;
        });
    },

    openPodDialog(pod = null) {
      if (pod !== null) {
        this.podDialog.fields.name = pod.name;
        this.podDialog.fields.description = pod.description;
        this.podDialog.fields.standard_room_rate = pod.standard_room_rate;
        this.podDialog.pod = pod;
        this.podDialog.isEditing = true;
      }

      this.podDialog.open = true;
    },

    resetPodDialog() {
      this.podDialog = {
        open: false,
        loading: false,
        errors: {},
        isEditing: false,
        pod: {},
        fields: {
          name: null,
          description: null,
          standard_room_rate: null,
          thumbnail_image: null,
        },
      };
    },

    savePod() {
      this.podDialog.loading = true;
      this.podDialog.errors = {};

      let formData = new FormData();
      formData.append("name", this.podDialog.fields.name);
      formData.append("description", this.podDialog.fields.description);
      formData.append(
        "standard_room_rate",
        this.podDialog.fields.standard_room_rate
      );

      if (this.podDialog.fields.thumbnail_image) {
        formData.append(
          "thumbnail_image",
          this.podDialog.fields.thumbnail_image
        );
      }

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.podDialog.isEditing,
        fields: formData,
      };

      if (this.podDialog.isEditing) {
        payload.podId = this.podDialog.pod.id;
      }

      this.$store
        .dispatch("arnprior/podsStore/savePod", payload)
        .then(() => this.resetPodDialog())
        .catch((err) => {
          if (err.response.status === 422) {
            this.podDialog.errors = err.response.data.errors;
          }

          this.podDialog.loading = false;
        });
    },

    openRateDialog(podId, rate = null) {
      this.rateDialog.fields.pod_id = podId;

      if (rate) {
        this.rateDialog.rate = rate;
        this.rateDialog.fields.room_rate = rate.room_rate;
        this.rateDialog.fields.from = rate.from;
        this.rateDialog.fields.to = rate.to;
        this.rateDialog.isEditing = true;
      }

      this.rateDialog.open = true;
    },

    resetRateDialog() {
      this.rateDialog = {
        open: false,
        loading: false,
        errors: {},
        isEditing: false,
        minDate: new Date().toISOString().slice(0, 10),
        rate: {},
        dates: [],
        fields: {
          pod_id: null,
          room_rate: null,
          from: null,
          to: null,
        },
      };
    },

    saveRate() {
      this.rateDialog.loading = true;
      this.rateDialog.errors = {};

      let payload = {
        appId: this.$route.params.id,
        podId: this.rateDialog.fields.pod_id,
        isEditing: this.rateDialog.isEditing,
        fields: this.rateDialog.fields,
      };

      if (this.rateDialog.isEditing) {
        payload.rateId = this.rateDialog.rate.id;
      }

      const dates = this.rateDialog.dates.sort();

      payload.fields.from = dates[0];
      payload.fields.to = dates[1];

      this.$store
        .dispatch("arnprior/podsStore/saveRate", payload)
        .then(() => this.resetRateDialog())
        .catch((err) => {
          this.rateDialog.errors = err.response.data.errors;
          this.rateDialog.loading = false;
        });
    },
  },
};
</script>
